import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Card, CardBody, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import { GET_SINGLE_TEMPLATE } from "../../Pages/settings/gql";
import { EmailModalInvoiceAttachmentProps } from "../../interfaces";
import EmailDraft from "../EmailDraft";
import CustomButton from "../layout/CustomButton";
import { EMAIL_MUTATION } from "./gql";

const EmailModal: FC<EmailModalInvoiceAttachmentProps> = ({
  isOpen, toggle, refetch, startDate, ccEmails, toEmail, id, invoiceId, emailContent, fileName, source }) => {
  const [emailMutation, { loading }] = useMutation(EMAIL_MUTATION, {
    onCompleted: () => {
      toast.success("email sent successfully")
      toggle()
    }
  })
  const [bodyContent, setBodyContent] = useState<string | null>();
  const [subject, setSubject] = useState<string | null>();
  const [additionalFiles, setAdditionalFiles] = useState<{ source: string, fileName: string }[]>([]);

  const { register, watch } = useForm()
  const { resendEmail } = watch()


  const [getTemplateQuery, { data }] = useLazyQuery(GET_SINGLE_TEMPLATE, {
    onCompleted: () => console.log("data ", data),
    onError: (err) => toast.error(err?.message)
  })

  const [mailContact, setMailContact] = useState<{ to: string[], ccContacts: string[]; bccContacts: string[] }>({
    to: [],
    ccContacts: [],
    bccContacts: [],
  });

  useEffect(() => {
    getTemplateQuery({
      variables: {
        module: resendEmail ? 'Invoice Aged Email' : 'Invoice Email'
      },
    })
  }, [resendEmail])


  useEffect(() => {
    if (isOpen) {
      setMailContact({
        to: [toEmail],
        ccContacts: ccEmails,
        bccContacts: ["johnnyrenaudo@realrepp.com"]
      })
    } else {
      setSubject('')
      setBodyContent('')
    }
  }, [isOpen])

  useEffect(() => {
    if (data) {
      setBodyContent(
        data?.getTemplateByModule?.template
          .replaceAll("{{clientName}}", emailContent?.clientName)
          .replaceAll("{{candidateFullName}}", emailContent?.candidateFullName)
          .replaceAll("{{placementJobTitle}}", emailContent?.placementJobTitle)
          .replaceAll("{{jobCity}}", emailContent?.jobCity)
          .replaceAll("{{jobStateCode}}", emailContent?.jobStateCode)
          .replaceAll("{{invoiceId}}", invoiceId)
          .replaceAll("{{agedDays}}", moment().diff(moment(startDate), 'days'))

      )
      setSubject(data?.getTemplateByModule?.subject
        .replaceAll("{{invoiceId}}", invoiceId)
        .replaceAll("{{placementJobTitle}}", emailContent?.placementJobTitle)
        .replaceAll("{{candidateFullName}}", emailContent?.candidateFullName)
        .replaceAll("{{agedDays}}", moment().diff(moment(startDate), 'days'))
      )
    }
  }, [data, isOpen])

  const handleSubmit = async () => {
    try {
      await emailMutation({
        variables: {
          input: {
            toContacts: mailContact.to,
            id,
            bodyContent: bodyContent?.replaceAll('<p>', '').replaceAll('</p>', '<br>'),
            subject,
            ccContacts: mailContact.ccContacts,
            bccContacts: mailContact.bccContacts,
            emailType: resendEmail ? 'Invoice Aged Email' : 'Invoice Email'
          },
          additionalFiles
        }
      })
      refetch()
    } catch (error) {
      toast.error("something went wrong")
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="for-modal-styling">
      <ModalBody>
        <div id="submittedEmail" className="writeup-wrap submission-package">
          <Card className="border-0">
            <CardBody className="p-0">
              <div className="dollar-addon-btn">
                <div className="d-flex my-3 justify-content-between">
                  <Label className="text-14  ">Email</Label>
                  <div className="d-flex align-items-center">
                    <span className="switchLabel">Initial Sending</span>
                    <label className="switch m-0 mx-2">
                      <Input type="checkbox" name="resendEmail" innerRef={register} />
                      <span className="slider"></span>
                    </label>
                    <span className="switchLabel">Aged Invoice</span>
                  </div>
                </div>
                <EmailDraft
                  fileName={fileName} source={source}
                  selectedFiles={additionalFiles} setSelectedFiles={setAdditionalFiles}
                  bodyContent={bodyContent} mailContact={mailContact} subject={subject}
                  setBodyContent={setBodyContent} setMailContact={setMailContact} setSubject={setSubject} />
              </div>
            </CardBody>
          </Card>
        </div>
      </ModalBody>

      <ModalFooter>
        <button type="button" onClick={toggle} className="buttonPrimary mr-3">
          Cancel
        </button>
        <CustomButton
          loading={loading}
          disabled={loading || !mailContact?.to?.length}
          buttonClick={handleSubmit}
          buttonText="Send Email"
        />
      </ModalFooter>
    </Modal>)

}
export default EmailModal