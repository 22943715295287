import { FC, Fragment, useRef, useState } from "react"
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal } from "reactstrap"
import { IInvoiceAttachment, InvoiceEmailHistory } from "../../Pages/interseller/steps/interfaceSteps"
import DELETE_ICON from '../../assets/images/delete-icon-menu.svg'
import EDIT_ICON from '../../assets/images/edit-icon-menu.svg'
import EMAIL_ICON from '../../assets/images/email-icon.svg'
import VIEW_ICON from '../../assets/images/view-icon.svg'
import { INVOICE_ATTACHMENT_LIST_HEADER } from "../../utils/constant"
import Loader from "../layout/Loader"
import NoDataFound from "../layout/NoDataFound"
import TableComponent from "../layout/TableComponent"
import InvoiceAttachmentModal from "./InvoiceAttachmentModal"
import { useMutation, useQuery } from "@apollo/client"
import { GET_ALL, REMOVE_MUTATION } from "./gql"
import ConfirmPopUp from "../ConfirmPopUp"
import { toast } from "react-toastify"
import Preview from "../../Pages/job-order/job-order-components/document-preview/Preview"
import { GET_SIGNED_URL } from "../../Pages/candidate/gql"
import { InvoiceAttachmentProps } from "../../interfaces"
import { moneyFormatter } from "../../utils/helper"
import MENU_ICON_1 from '../../assets/images/menu-icon-1.svg';
import EmailModal from "./EmailModal"
import { DownArrowIcon } from "../../assets/images/svgs"
import InvoiceEmailingDetailModal from "./InvoiceEmailingDetailModal"


const InvoiceAttachments: FC<InvoiceAttachmentProps> = ({
  placementId, invoiceId, startDate, placementStatus, ccEmails, toEmail, emailContent }) => {
  const dropdownRef = useRef(null);
  const { refetch, data, loading } = useQuery(GET_ALL, { variables: { placementId } })
  const [signedUrlGetter] = useMutation(GET_SIGNED_URL);
  const [removeMutation, { loading: removing }] = useMutation(REMOVE_MUTATION)
  const [openAddAttachment, setOpenAddAttachment] = useState<boolean>(false)
  const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [emailReportModal, setEmailReportModal] = useState<boolean>(false);
  const [emailingHistoryModal, setEmailingHistoryModal] = useState<boolean>(false);
  const [documentName, setDocumentName] = useState<string>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [editableRecord, setEditableRecord] = useState<IInvoiceAttachment | null>()
  const [emailHistory, setEmailHistory] = useState<InvoiceEmailHistory[]>([])


  const toggle = () => {
    setOpenAddAttachment(!openAddAttachment)
    setEditableRecord(null)
  }
  const confirmToggle = () => setConfirmPopUp(!confirmPopUp)

  const getPreviewUrl = async (remoteFilePath: string) => {
    const response = await signedUrlGetter({
      variables: {
        remoteFilePath
      },
    });

    if (response?.data?.getSignedUrl?.signedUrl) {
      setDocumentUrl(response?.data?.getSignedUrl?.signedUrl)
      setIsPreview(true);
    }
  }
  const deleteAttachment = async () => {
    const response = await removeMutation({ variables: { id: editableRecord?.id } })
    if (response?.data) {
      toast.success("attachment removed")
      confirmToggle()
      refetch()
      return
    }
    toast.error("something went wrong")
  }

  const toggleDropdown = (index) => {
    if (activeDropdown === index) {
      setActiveDropdown(null);
      setDropdownOpen(false); // close dropdown
    } else {
      setActiveDropdown(index);
      setDropdownOpen(true); // open dropdown
    }
  };

  const mapInvoiceAttachmentData = (index: number, {
    id, fileName, fileUrl, attachmentType, secondFile, amount, receivedDate, uploadedBy, invoiceEmailHistory }: IInvoiceAttachment) => {
    return <>
      <td>{fileName}</td>

      <td>
        <div className="d-flex align-items-center gap-1">
          {attachmentType!.charAt(0).toUpperCase() + attachmentType!.slice(1)}
          {attachmentType === "invoice" && <Dropdown
            className="ml-1"
            isOpen={activeDropdown === index && dropdownOpen}
            toggle={() => toggleDropdown(index)}
            innerRef={dropdownRef}
          >
            <DropdownToggle className="menuButton dark-btn">
              <DownArrowIcon />
            </DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: "100vh",
                      },
                    };
                  },
                },
              }}
              container={document.body}
              target={() => dropdownRef.current}
              isOpen={dropdownOpen}
              toggle={toggle}
            >
              <DropdownItem
                className="tableMenuItem"
                onClick={() => {
                  setEmailHistory(invoiceEmailHistory!)
                  setEmailingHistoryModal(!emailingHistoryModal)
                }}
              >
                <img src={MENU_ICON_1} alt="icon" />
                View Details
              </DropdownItem>


            </DropdownMenu>
          </Dropdown>}
        </div>
      </td>

      <td>{moneyFormatter(Number(amount?.replaceAll(',', '')) || 0)}</td>

      <td>{uploadedBy?.fullName}</td>

      <td>
        <div className="w-30px">

          <Button className="bg-transparent border-0 p-0">
            <img className="mr-3" src={VIEW_ICON}
              onClick={async () => {
                await getPreviewUrl(fileUrl!)
                setDocumentName(fileName)
              }}
              alt="view" />
          </Button>

          <Button className="bg-transparent border-0 p-0">
            <img className="mr-3" src={EDIT_ICON}
              onClick={() => {
                setEditableRecord({ id, attachmentType, fileName, secondFile, amount, invoiceId, receivedDate })
                setOpenAddAttachment(true)
              }}
              alt="edit" />
          </Button>

          <Button
            onClick={() => {
              setEmailReportModal(true)
              setEditableRecord({ id, fileName, fileUrl })
            }}
            disabled={attachmentType !== "invoice"}
            className="btn bg-transparent p-0 border-0">
            <img
              src={EMAIL_ICON}
              alt="email"
              width="18px"
            />
          </Button>

          <Button className="bg-transparent border-0 p-0">
            <img src={DELETE_ICON}
              onClick={() => {
                setConfirmPopUp(true)
                setEditableRecord({ id })
              }}
              alt="delete" />
          </Button >
        </div>
      </td>
    </>
  }
  return <>
    <div className="mt-3">
      <Fragment>
        <div className="jobOrderRevamp placementDetail">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h5 className="mr-2 noMarginHeading">Invoice Attachments</h5>
            <button
              // disabled={!(userRoles?.includes(ROLES.ACCOUNTING_MANAGER) || userRoles?.includes(ROLES.EXECUTIVE))}
              className="buttonGenericStyle filledButton ml-3"
              onClick={() => {
                setEditableRecord({ invoiceId })
                setOpenAddAttachment(true)
              }}
            >
              Invoice Attachments
            </button>
          </div>

          <TableComponent tableHeader={INVOICE_ATTACHMENT_LIST_HEADER} >
            {!loading && !data?.invoiceAttachments?.length && (
              <tbody>
                <tr>
                  <td colSpan={9} className="empty-table-td">
                    <NoDataFound text="No Invoice Attachment Found!" />
                  </td>
                </tr>
              </tbody>
            )}

            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={11}>
                    <Loader loaderClass="sm-height " />
                  </td>
                </tr>
              </tbody>
            ) :
              <tbody>
                {
                  data?.invoiceAttachments?.map((item: IInvoiceAttachment, index: number) => {
                    return (
                      <tr key={index} >
                        {mapInvoiceAttachmentData(index, item)}
                      </tr>
                    );
                  })
                }
              </tbody>}
          </TableComponent>
        </div>

      </Fragment>
    </div>
    <InvoiceAttachmentModal editableRecord={editableRecord!}
      isOpen={openAddAttachment} toggle={toggle} refetch={refetch} placementId={placementId!} placementStatus={placementStatus!} />

    <ConfirmPopUp disabled={removing} className="revampDialog revampDialogWidth"
      confirmText="Are you sure you want to delete this attachment?"
      isOpen={confirmPopUp} toggle={confirmToggle} confirmAction={deleteAttachment}
      modalHeading="Delete Attachment" btnText="Delete" btnColor="primary" loading={removing}
    />

    <Modal
      isOpen={isPreview}
      size="xl"
      toggle={() => setIsPreview(false)}
      centered={true}
      className="revampDialog border-0"
    >
      <div className="pdf-viewer">
        <Preview
          documentName={documentName}
          documentUrl={documentUrl}
          isDownload={false}
          fileStatus={'Original'}
        />
      </div>
    </Modal>

    <EmailModal
      emailContent={emailContent!} refetch={refetch}
      id={editableRecord?.id!} invoiceId={invoiceId!}
      startDate={startDate!} fileName={editableRecord?.fileName!}
      ccEmails={ccEmails} toEmail={toEmail} source={editableRecord?.fileUrl!}
      isOpen={emailReportModal} toggle={() => setEmailReportModal(!emailReportModal)} />

    <InvoiceEmailingDetailModal emailHistory={emailHistory!}
      isOpen={emailingHistoryModal} toggle={() => setEmailingHistoryModal(!emailingHistoryModal)} />
  </>
}

export default InvoiceAttachments