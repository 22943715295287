import { useLazyQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import ReactQuill from "react-quill";
import { Badge, Button, Form, Input, InputGroup, InputGroupAddon, Modal, Spinner } from "reactstrap";
import { AttachFileIcon } from "../assets/images/svgs";
import Preview from "../Pages/job-order/job-order-components/document-preview/Preview";
import { GET_SIGNED_URL } from "../Pages/settings/gql";
import { bucketFolders, EMAIL_PATTERN, reactQuillReg } from "../utils/constant";
import { uploadFile } from "../utils/helper";

const EmailDraft = ({ selectedFiles, setSelectedFiles, fileName, source,
  mailContact, setMailContact, bodyContent, setBodyContent, subject, setSubject }) => {
  const [getSignedURL] = useLazyQuery(GET_SIGNED_URL);

  const [errMsg, setErrMsg] = useState('');
  const [fileErr, setFileErr] = useState('');
  const [uploading, setUploading] = useState<boolean>()
  const [viewDocument, setViewDocument] = useState<boolean>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [documentName, setDocumentName] = useState<string>()

  const mailSelected = (e: any) => {
    if (e.keyCode === 13) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(e.target.value);
      if (test) {
        let mails = [...mailContact[e.target.name]];
        mails.push(e.target.value);
        setMailContact({ ...mailContact, [e.target.name]: mails });
        e.target.value = '';
        setErrMsg('');
      } else {
        console.log("ssss")
        setErrMsg('Email is not valid!');
      }
    }
  };

  const SignedUrl = useCallback(async (url) => {
    const { data } = await getSignedURL({ variables: { url } })
    const { getSignedUrl } = data || {};
    const { signedUrl } = getSignedUrl || {};
    return signedUrl;
  }, [getSignedURL])

  const checkMail = (e) => {
    if (e.target.value) {
      const mailRegex = new RegExp(EMAIL_PATTERN);
      const test = mailRegex.test(e.target.value);
      if (test) {
        let mails = [...mailContact[e.target.name]];
        mails.push(e.target.value);
        setMailContact({ ...mailContact, [e.target.name]: mails });
        e.target.value = '';
        setErrMsg('');
      } else {
        console.log("yyyy")
        setErrMsg('Email is not valid!');
      }
    }
  };

  const removeAttachment = (indexOf: number) => {
    let files = [...selectedFiles];
    files = files.filter((item, index) => index !== indexOf);
    setSelectedFiles(files);
  };

  return (
    <>
      <Form>
        <div className="to-header">
          <InputGroup className="shadow-none">
            <InputGroupAddon addonType="prepend" className="styleCss">To</InputGroupAddon>
            <div style={{ width: '74%' }} className="">
              <div className="d-flex">
                {mailContact.to?.map((item) => (
                  <div className="position-relative d-flex">
                    <Badge color="light" className="mx-1 my-auto text-truncate textTruncateWidth pr-4">
                      {item}
                    </Badge>
                    <span
                      className="forMailRemove text-dark"
                      onClick={() => {
                        setMailContact({
                          ...mailContact,
                          to: mailContact.to.filter((email) => email !== item),
                        });
                      }}>  x</span>
                  </div>
                ))}
                <Input
                  style={{
                    border: '0px',
                  }}
                  autoComplete="off"
                  name="to"
                  onKeyUp={(e: any) => mailSelected(e)}
                  onBlur={(e) => checkMail(e)}
                />
              </div>
            </div>
          </InputGroup>
        </div>
        <div className="border border-bottom-0"
          style={{ borderColor: '#e5e9f8' }}>
          <InputGroup className="shadow-none border-bottom-0 rounded-0">
            <InputGroupAddon addonType="prepend" className="styleCss" >
              Cc
            </InputGroupAddon>
            <div style={{ width: '74%' }} className="">
              <div className="d-flex">
                {mailContact.ccContacts?.map((item) => (
                  <div className="position-relative d-flex">
                    <Badge color="light" className="mx-1 my-auto text-truncate textTruncateWidth pr-4">
                      {item}
                    </Badge>
                    <span
                      className="forMailRemove text-dark"
                      onClick={() => {
                        setMailContact({
                          ...mailContact,
                          ccContacts: mailContact.ccContacts.filter((email) => email !== item),
                        });
                      }}>  x</span>
                  </div>
                ))}
                <Input
                  style={{
                    border: '0px',
                  }}
                  autoComplete="off"
                  name="ccContacts"
                  onKeyUp={(e: any) => mailSelected(e)}
                  onBlur={(e) => checkMail(e)}
                />
              </div>

            </div>
          </InputGroup>
        </div>

        <div
          className="border border-bottom-0"
          style={{ borderColor: '#e5e9f8' }}
        >
          <InputGroup className="shadow-none">
            <InputGroupAddon
              addonType="prepend"
              className="styleCss"
            >
              Bcc
            </InputGroupAddon>
            <div style={{ width: '74%' }} className="">
              <div className="d-flex">
                {mailContact.bccContacts?.map((item) => (
                  <div className="position-relative  d-flex">
                    <Badge
                      color="light"
                      className="mx-1 my-auto text-truncate textTruncateWidth"
                      style={{
                        padding: '5px 20px 5px 5px ',
                      }}
                    >
                      {item}
                    </Badge>
                    <span
                      className="forMailRemove text-dark"
                      onClick={() => {
                        setMailContact({
                          ...mailContact,
                          bccContacts: mailContact.bccContacts.filter((email) => email !== item),
                        });
                      }}
                    >
                      x
                    </span>
                  </div>
                ))}
                <Input
                  autoComplete="off"
                  name="bccContacts"
                  onKeyUp={(e: any) => mailSelected(e)}
                  onBlur={(e) => checkMail(e)}
                  className="border-0"
                />
              </div>
            </div>
          </InputGroup>
        </div>

        <div className="to-subject">
          <InputGroup className="shadow-none">
            <InputGroupAddon addonType="prepend" className="styleCss">
              Subject
            </InputGroupAddon>
            <Input
              autoComplete="off"
              className="border-0"
              name="subject"
              value={subject!}
              onChange={({ target: { value } }) => {
                setSubject(value);
              }}
            />
          </InputGroup>
        </div>
        <div>
          <div className="editor-height">
            <ReactQuill
              value={bodyContent!}
              onChange={(value) => {
                if (reactQuillReg.test(value)) {
                  setBodyContent(null);
                } else {
                  setBodyContent(value);
                }
              }}
            />
          </div>

          <div className="user-info upload-btn-wrapper mt-3">
            <Input
              type="file"
              // accept=".doc, .docx,.pdf"
              onChange={async ({ target }) => {
                setFileErr('');
                setUploading(true)
                const { fileName, source } = await uploadFile(
                  new File([target.files![0]!], target.files![0]!.name, { type: target.files![0]!.type }),
                  bucketFolders.invoiceAttachments,
                  false, "attachment"
                )
                setUploading(false)

                setSelectedFiles([...selectedFiles, { source, fileName }]);
              }}
            />

            <Button
              type="button"
              disabled={uploading}
              className="btn-outline_prime border-none bg-transparent px-0"
            >
              <AttachFileIcon /> Attach File
              {uploading && (
                <Spinner size="sm" style={{ color: "#eaa827" }} className="mr-2" />
              )}
            </Button>
          </div>
          <small className="d-block text-info">{fileErr}</small>

          <div className="">
            <Badge
              color="light"
              className="mx-1 my-auto text-truncate text-14 font-weight-normal textTruncateWidth text-myWarning badge-submission-light"
              style={{
                padding: '5px 20px 5px 5px',
                cursor: 'pointer',
              }}
              onClick={async () => {
                setViewDocument(true)
                setDocumentUrl(await SignedUrl(source))
                setDocumentName(fileName)
              }}
            >
              {fileName}
            </Badge>
            {[...selectedFiles]?.map(({ source, fileName }, index) => (
              <div>
                <div className="d-flex pt-2 mb-2  upload-document-list">
                  <div
                    className="position-relative  d-flex"
                    key={index}
                  >
                    <Badge
                      color="light"
                      className="mx-1 my-auto text-truncate text-14 font-weight-normal textTruncateWidth text-myWarning badge-submission-light"
                      style={{
                        padding: '5px 20px 5px 5px',
                        cursor: 'pointer',
                      }}
                      onClick={async () => {
                        setViewDocument(true)
                        setDocumentUrl(await SignedUrl(source))
                        setDocumentName(fileName)
                      }}
                    >
                      {fileName}
                    </Badge>
                    <span
                      className="forMailRemove text-myWarning"
                      style={{ top: '-2px' }}
                      onClick={() => removeAttachment(index)}
                    >
                      x
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <small className="text-danger">{errMsg}</small>
      </Form>

      <Modal
        isOpen={viewDocument}
        toggle={() => setViewDocument(!viewDocument)}
        centered={true}
        size="xl"
        className="revampDialog border-0"
      >
        <div className="pdf-viewer">
          <Preview
            documentUrl={documentUrl}
            documentName={`${documentName}.pdf`}
            isDownload={true}
          />
        </div>
      </Modal>
    </>
  )
}
export default EmailDraft